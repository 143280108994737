.footer {
  @apply border-t text-white;

  background-color: #0054a4;

  & .logo {
    width: 206px;
  }

  & .footerMenu {
    & > ul {
      @apply flex flex-wrap mt-2 text-xs;

      & > li {
        @apply ml-0 mr-4;

        &:last-child {
          @apply md:mr-0;
        }
      }
    }
  }

  & a {
    &:hover,
    &:focus {
      @apply underline;
    }
  }

  & .socialmedia {
    @apply text-4xl mb-2 flex md:justify-end;
  }

  & .copyright {
    @apply md:text-right text-xs;
  }

  & .whatsapp {
    height: 60px;
    width: 60px;
    position: fixed;
    top: 140px;
    right: 8px;
    z-index: 10;
  }
}
