.header {
  @apply sticky top-0 pb-1 transition-all z-50 border-b shadow bg-white;

  & .top {
    @apply text-white;

    background-color: #fbb034;

    & .top_wrapper {
      @apply container grid grid-cols-2 mb-1 items-center;

      & nav ul {
        @apply justify-start text-sm;

        & li a {
          @apply pl-3 pr-0;
        }

        & li a::after {
          content: '|';
          margin-left: 10px;
        }

        & li:last-child a::after {
          display: none;
        }
      }
    }
  }

  & .search {
    @apply hidden col-span-1 justify-end; /* flex */
  }

  & .navigation {
    @apply flex justify-between; /* items-center flex-col */

    @screen lg {
      @apply flex-row items-end justify-between;
    }

    & .logoWrapper {
      @apply my-auto mx-0;
    }

    & .logo {
      @apply w-auto h-14;

      @screen lg {
        @apply mb-0;
      }
    }
  }

  & ion-icon {
    display: flex;
    color: #0054a4;
  }
}

.skip {
  @apply absolute text-sm p-2;

  top: -200px;
  left: -200px;
  z-index: 9999;
  background: #fff;

  &:focus {
    @apply visible opacity-100;

    top: 10px;
    left: 10px;
  }
}
