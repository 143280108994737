@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  /* Write your own custom base styles here if necessary - https://tailwindcss.com/docs/adding-base-styles#using-css */
}

/* Write you own custom classes here if necessary */

body {
  @apply font-sans;
}

.chatbot-iframe {
  overflow: hidden;
  border: 0;
  align-self: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.gtm-iframe {
  display: none;
  visibility: hidden
}

#page-content {
  /* Global Styles */

  & .innerWrap {
    @apply max-w-5xl m-auto py-2;
  }

  & ul:not(.breadcrumbs),
  & ol,
  & p {
    & a {
      @apply text-blue-700 no-underline;

      &:hover,
      &:focus {
        @apply no-underline;
      }
    }
  }

  & .stk-block-background ul a {
    @apply text-white;
  }

  & .stk-block-icon-list.lulu-lemon-inline li {
    display: inline;
    padding: 5px;

    & a {
      color: #d3fc32;
    }
  }

  /* Body Heading Styles */
  & h1,
  & h2,
  & h3,
  & h4 {
    @apply mb-6 font-semibold;

    color: #0054a4;
  }

  & h1 {
    @apply text-5xl md:text-7xl leading-10;

    /* font-size: 68px; */

    /* line-height: 80px; */
  }

  & h2 {
    /* @apply text-4xl; */

    font-size: 42px;
  }

  & h3 {
    @apply mb-4;

    font-size: 26px;
  }

  & h4 {
    @apply mb-4 text-lg;
  }

  /* Rich Text Styles */
  & p,
  & ul,
  & ol,
  & td {
    @apply mb-4 text-base font-light leading-6 text-gray-700;
  }

  /* Table */
  & tr:nth-child(odd) {
    --tw-bg-opacity: 0.1;

    background-color: rgb(0 84 164 / var(--tw-bg-opacity));
  }

  & th {
    color: #0054a4;
    background-color: #fbfbfb;
  }

  & thead tr {
    border-bottom-color: rgba (0, 84, 164, 10%) !important;
  }

  & td {
    padding-top: 15px;
    padding-bottom: 15px;

    & img {
      margin: 0 auto;
    }
  }

  & .table-wrap {
    max-height: 75vh;
    overflow-y: auto;
    display: block;

    & thead {
      position: sticky;
      top: 0;
      background: white;
    }
  }

  /* Button */

  & .yellow {
    & .stk-button:hover {
      background-color: white !important;

      & .stk-button__inner-text {
        color: #fbb034;
      }
    }
  }

  /* List */

  & li span,
  & li a {
    vertical-align: text-bottom;
  }

  & .tick-mark ul li {
    position: relative;
    list-style-type: none;
    padding: 2px 0;

    &::before {
      content: '';
      position: absolute;
      top: 4px;
      left: -30px;
      width: 20px;
      height: 20px;
      background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxOTAgMTkwIiBzdHlsZT0iZmlsbDogI2ZiYjAzNCAhaW1wb3J0YW50OyBjb2xvcjogI2ZiYjAzNCAhaW1wb3J0YW50O3RyYW5zZm9ybTogcm90YXRlKGRlZykgIWltcG9ydGFudDsiPjxwb2x5Z29uIHBvaW50cz0iMTczLjgsMjguNCA2MC40LDE0MS44IDE1LjcsOTcuMiA1LjEsMTA3LjggNjAuNCwxNjMgMTg0LjQsMzkgMTczLjgsMjguNCIgZmlsbD0iI2ZiYjAzNCIgc3Ryb2tlPSIjZmJiMDM0IiBzdHlsZT0iZmlsbDogcmdiKDI1MSwgMTc2LCA1Mik7IHN0cm9rZTogcmdiKDI1MSwgMTc2LCA1Mik7Ii8+PC9zdmc+') !important;
    }
  }

  & .wp-block-stackable-feature .stk-block-text__text {
    font-size: 20px;
  }

  & .sidebox-blue a:visited {
    color: white !important;
  }

  & p.stk-block-heading__text {
    margin: 0;
  }

  & p.stk-block-text__text img {
    display: inline-block;
    margin: 0 5px;
  }

  /* forms */

  & form {
    @apply p-0;

    & label {
      @apply text-slate-600 dark:text-slate-200 text-base font-medium block pb-1;
    }

    & input {
      @apply w-full outline-0 rounded-md border-solid border-2 border-slate-400 py-4 px-3 pr-10 text-lg leading-5 text-gray-900 focus:ring-0;
    }

    & textarea {
      @apply w-full h-48 outline-0 rounded-md border-solid border-2 border-slate-400 py-4 px-3 pr-10 text-lg leading-5 text-gray-900 focus:ring-0;
    }

    & select {
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3E%3Cpath stroke='%236B7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='m6 8 4 4 4-4'/%3E%3C/svg%3E");
      background-position: right 0.5rem center;
      background-repeat: no-repeat;
      background-size: 1.5em 1.5em;

      @apply appearance-none w-full rounded-md border-solid border-2 py-3.5 px-3 pr-10 text-lg text-gray-900 bg-gray-50 border-slate-400 focus:ring-0;
    }

    & button {
      @apply justify-center mt-6 rounded-md bg-sky-900 bg-opacity-80 px-16 py-4 text-sm font-medium text-white hover:bg-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75;
    }
  }
}
